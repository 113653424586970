// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentInterface } from '../app/_shared/interfaces/environment.interface';

export const environment: EnvironmentInterface = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAg-Yltb-bL1Dp32-5W4IZQ1azejJjvYgU",
    authDomain: "mycellar-dev.firebaseapp.com",
    databaseURL: "https://mycellar-dev-default-rtdb.firebaseio.com",
    projectId: "mycellar-dev",
    storageBucket: "mycellar-dev.appspot.com",
    messagingSenderId: "103092717234",
    appId: "1:103092717234:web:e409256cbd997f73342bab",
    measurementId: "G-FVNQ2D6800"
  },
  appName: 'Cellar',
  apiUrl: 'https://us-central1-mycellar-dev.cloudfunctions.net',
  apiPosUrl: 'https://us-central1-mycellar-dev.cloudfunctions.net',
  mapsApiKey: 'AIzaSyBs4kc0MSe-kxU8w2lrDfhauw2Xfq_yqKc',
  baseUrl: 'https://cms.dev.cellarco.io',
  release: '00.15',
  envName: 'DEV',
  hoodieAPIkey: '64b0137687c0842a46a7a150ddafb443',
  searchAPIkey: '',
  makioUrl: 'https://makio-dev.leafvip.app',
	appCompanyName: 'Company',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
