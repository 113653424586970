export function convertSnaps<T>(results: any) {
    return <T[]> results.docs.map((snap: any) => {
        return {
            id: snap.id,
            exists: snap.exists,
            ...<any>snap.data()
        }
    })
}

export function sortByKey<T>(results: any[], key: string) {
    return <T[]> results.sort((a, b) => {
        const nameA = a?.[key].toUpperCase(); // ignore upper and lowercase
        const nameB = b?.[key].toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
    });
}
