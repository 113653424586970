// export const budtenderRole = 'budtender' as const;
// export const managerRole = 'manager' as const;
// export const buyerRole = 'buyer' as const;
// export const otherRole = 'other' as const;
export const demoRole = 'demo' as const;

// export const leafJobTypes = [budtenderRole, managerRole, buyerRole, otherRole, demoRole] as const;
// export const activeLeafJobTypes = [budtenderRole, managerRole, buyerRole] as const;
// export type LeafJobType = typeof leafJobTypes[number];

export const distributorRole = 'distributor' as const;
export const ambassadorRole = 'ambassador' as const;
export const onpremiseRole = 'onpremise' as const;
export const offpremiseRole = 'offpremise' as const;

// export const cellarJobTypes = [distributorRole, ambassadorRole, demoRole, onpremiseRole, offpremiseRole] as const;
// export const activeCellarJobTypes = cellarJobTypes;
// export type CellarJobType = typeof cellarJobTypes[number];

// export type AllJobTypes = CellarJobType | LeafJobType;

// export type ActiveJobType = typeof activeCellarJobTypes[number];
// export const activeRoles = activeCellarJobTypes;

export const activeRoles = [distributorRole, ambassadorRole, demoRole, onpremiseRole, offpremiseRole] as const;
